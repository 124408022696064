.modal-content {
	min-height: 75%;
}

.modal-header {
	display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
	padding-top: 0.8rem;
}

.modal-title {
	margin-bottom: 0.2rem;
}

.modal-subtitle {
	font-size: 0.9rem;
}

.modal-subtitle-sm {
	font-size: 0.8rem;
}

.modal-body {
	position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.nav-tabs .nav-link:hover {
	cursor: pointer;
}

.tab-pane {
	padding-top: 1.5rem;
}

.history {
	flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
}

.timeline {
	width: 90%;
	margin: 0;
    padding: 0 1rem;
    height: fit-content;
	position: relative;
}

.timeline::before {
    background-color: #ebebeb;
	left: 50%;
    margin-left: -2px;
	content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 4px;
}

.timeline-element {
    margin: 0;
    margin-bottom: 1.5rem;
	position: relative;
}

.timeline-element-icon {
	margin-left: -10px;
    visibility: visible;
	width: 1rem;
    height: 1rem;
    border: 0;
    box-shadow: none;
	left: 50%;
	position: absolute;
    top: 9px;
	border-radius: 50%;
}

.timeline-element-content-arrow {
	top: 13px;
    left: 100%;
    transform: rotate(180deg);
    content: '';
    position: absolute;
    right: 100%;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-right: 10px solid #f7f7f7;
}

.timeline-element-content {
    visibility: visible;
	box-shadow: none;
    border-radius: 0;
    padding: 0.6rem;
	margin-left: 0;
    width: 44%;
	position: relative;
	background: #f7f7f7;
	border-radius: 5px;
}

.timeline-element-content-Healthy {
	background-color: rgb(0,128,0,0.1);
}
.timeline-element-content-Degraded {
	background-color: rgb(255,165,1,0.1);
}
.timeline-element-content-Unhealthy {
	background-color: rgb(255,2,0,0.1);
}

.timeline-element-content-Healthy .timeline-element-content-arrow {
	border-right-color: rgb(0,128,0,0.1);
}
.timeline-element-content-Degraded .timeline-element-content-arrow {
	border-right-color: rgb(255,165,1,0.1);
}
.timeline-element-content-Unhealthy .timeline-element-content-arrow {
	border-right-color: rgb(255,2,0,0.1);
}

.timeline-element-status {
	font-size: 0.9rem;
	font-weight: bold;
}

.timeline-element-description {
	font-size: 0.9rem;
	margin-top: 2px;
}

.timeline-element-date {
    position: absolute;
    width: 100%;
    left: 127%;
    top: 0px;
	float: left;
    padding: .6em 0;
    opacity: .7;
	visibility: visible;
}

.timeline-element>div {
    min-height: 1px;
}

.timeline-element:after {
    content: "";
    display: table;
    clear: both;
}

.timeline-element.timeline-element--right .timeline-element-content,
.timeline-element:nth-child(even):not(.timeline-element--left) .timeline-element-content {
    float: right;
}

.timeline-element:nth-child(even):not(.timeline-element--left) .timeline-element-content .timeline-element-date {
    left: auto;
    right: 127%;
    text-align: right;
}

.timeline-element:nth-child(even):not(.timeline-element--left) .timeline-element-content-arrow {
    top: 13px;
    left: auto;
    right: 100%;
    transform: rotate(0);
}

.timeline-element:nth-child(odd):not(.timeline-element--left) .timeline-element-status,
.timeline-element:nth-child(odd):not(.timeline-element--left) .timeline-element-description {
    text-align: right;
}
