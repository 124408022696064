.health-table {
	overflow: hidden;
}

.health-table th {
    border-top: 5px solid #0057AD;
    border-bottom-width: 3px !important;
    font-weight: normal;
    line-height: 1.2rem;
    padding: 1rem;
}

.health-table td {
    padding: 0.4rem;
}

.health-table th, .health-table td {
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.health-table th:first-child, .health-table td:first-child {
    border-right-width: 1px;
}

.health-table th:last-child, .health-table td:last-child {
    border-right: none;
}

.health-table tr:hover td {
    background-color: #f8f9fa !important;
}

.health-table td.highlight {
    background-color: #f8f9fa;
}

.health-table tr:hover th,
.health-table tr:hover td {
	position: relative;
}

.health-table tr:hover th:hover::after,
.health-table tr:hover td:hover::after
{
	content: "";
	position: absolute;
	background-color: #f8f9fa;
	left: 0;
	top: -5000px;
	height: 10000px;
	width: 100%;
	z-index: -1;
}

.health-table tr:hover td:hover .status {
	cursor: pointer;
}

.health-table tr:hover td:first-child:hover {
	background-color: #f8f9fa !important;
}

.health-table td.has-status {
	padding: 0;
	height: 1px;
}

.health-table td.has-status .status {
	padding: 0.3rem;
	height: 100%;
}

.health-table-sm th {
	padding: 0.7rem;
}

.health-table-sm td {
	padding: 0.3rem;
}
